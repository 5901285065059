<template>
    <div id="navbar">

        <div class="logo-group h-100 d-flex flex-direction-row align-items-center" @click="goHome">
            <img src="@/assets/images/winfun_logo.png" alt="Winfun Logo" class="logo">
            <span class="official-store">OFFICIAL STORE</span>
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {
                
            }
        },
        mounted () {

        },
        methods : {
            goHome () {
                if (this.$route.path != '/') this.$router.push('/');
            },
        }
    }
</script>

<style scoped>
    #navbar {
        height: 100px;
        background: #131843;
        padding: 25px 50px;

        display: flex;
        flex-direction: row;
        align-items: center;
    }

    #navbar .logo-group {
        cursor: pointer;
    }

    #navbar .logo {
        max-height: 100%;
    }

    #navbar span.official-store {
        color: white;
        font-size: 1.25em;
        font-weight: 500;
        margin-left: 20px;
    }
</style>