<template>
    <div id="NotFound">

        <div v-if="chosen">
            <i class="fas fa-7x" :class="chosen[0]"></i>
            <i class="fas fa-7x" :class="chosen[1]"></i>
            <i class="fas fa-7x" :class="chosen[2]"></i>
        </div>
        <h1 class="mb-0 mt-5">Page Not Found</h1>

        <button
            class="btn mt-5"
            type="button"
            @click="$router.push('/')"
        >Back To Home</button>

    </div>
</template>

<script>
    export default {
        data () {
            return {
                icons : [
                    "fa-asterisk",
                    "fa-bug",
                    "fa-poo",
                    "fa-radiation",
                    "fa-fire",
                    "fa-chess",
                    "fa-bell-on",
                    "fa-signal-stream",
                    "fa-sack-dollar",
                ],
                chosen : null
            }
        },
        mounted () {
            this.chooseAtRandom();
        },
        methods : {
            chooseAtRandom () {
                let chosen = [];
                for (var i = 0; i < 3; i++) {
                    //Random Position
                    let num = this.randomInt(0, 1000) % this.icons.length;

                    //Add To Chosen
                    chosen.push(this.icons[num]);

                    //Remove From Options
                    this.icons.splice(num, 1);
                }

                this.chosen = chosen;
            }
        }
    }
</script>

<style scoped>
    #NotFound {
        height: 100vh;
        width: 100vw;

        background: #7F7F7F;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #NotFound i {
        padding: 10px 50px;
    }

    #NotFound .btn {
        background: silver;
    }

    #NotFound .btn:hover {
        background: white;
        color: black;
    }
</style>