<template>
    <div id="vision">

        <div class="container">
            <p class="vision-title">To create health, wisdom and happiness for children's growth</p>
            <p class="vision-title">To become a world-class educational group and unlock children's unlimited potential</p>

            <!-- <div class="row m-0">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-6 vision-box" v-for="(singleVision, index) in visionData">
                    <img
                        :src="require('@/assets/images/vision' + (index+1) + '.png')"
                        :alt="'Vision ' + (index+1)"
                        style="width: 100%;height: auto;"
                    >
                    <p class="mt-3" style="font-weight: bold;">{{ singleVision.title }}</p>
                    <p>{{ singleVision.description }}</p>
                </div>
            </div> -->

            <div class="row">
                <div class="col-lg-6 col-md-6 col-12 " v-for="(singleVision, index) in visionData" style="padding: 40px">
                    <div class="vision-box h-100">
                        <img
                            :src="require('@/assets/images/vision' + (index+1) + '.png')"
                            :alt="'Vision ' + (index+1)"
                            style="width: 80px; height: auto;"
                        >
                        <h5 class="mt-3" style="font-weight: bold;">{{ singleVision.title }}</h5>
                        <p>{{ singleVision.description }}</p>
                    </div>
                </div>
            </div>
        </div>

        <img class="c1" src="@/assets/images/circle.svg">
        <img class="c2" src="@/assets/images/circle.svg">
        <img class="stars" src="@/assets/images/stars.svg">

    </div>
</template>

<script>
    export default {
        data () {
            return {
                visionData : [
                    {title:"Creativity", description:"More imagination space for children to exert endless creativity"},
                    {title:"Fun", description:"Win multiple inspirations during the fun process"},
                    {title:"Education", description:"Systematic learning to improve children's comprehensive ability"},
                    {title:"Safety", description:"Winfun® products meet international safety testing standards"},
                ]
            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    #vision {
        padding: 100px 0;
        position: relative;
        overflow: hidden;
    }

    #vision .c1 {
        position: absolute;
        top: -25%;
        left: -40%;
        z-index: -1;
    }
    #vision .c2 {
        position: absolute;
        bottom: -25%;
        right: -40%;
        z-index: -1;
    }

    #vision .stars {
        position: absolute;
        top: 100px;
        right: 50px;
        z-index: -1;
    }

    .vision-title {
        font-weight: bold;
        font-size: 24px;
        color: #626262;
    }

    /* #app:not(.mobile) #vision .vision-box {
        padding: 40px;
    } */

    #vision .vision-box {
        padding: 40px;
        background-color: #FFF3F6;
        border-radius: 15px;
    }

    #vision .vision-box h5 {
        font-size: 2.25rem;
        font-weight: bold;
    }

    #vision .vision-box p {
        font-size: 18px;
    }
</style>