import moment from 'moment'
import XLSX from 'xlsx'
import qs from 'qs'

var methods = {
    capitalize(s) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
    errorPrint(errorMessage) {//PRINTS ERROR IN RED COLOR
        var error = "%c" + errorMessage;
        console.log(error, 'color:#ff0000')
    },
    isRTL() {
        return document.documentElement.getAttribute('dir') === 'rtl' || document.body.getAttribute('dir') === 'rtl'
    },
    numberWithCommas(x) {
        return parseInt(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    randomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    },
    isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    },
    toCapitalizedWords(name) {
        var words = name.match(/[A-Za-z][a-z]*/g) || [];
        return words.map(this.capitalize).join(" ");
    },
    exportExcel(data, title) {
        let wb = XLSX.utils.book_new()
        let exportFileName = title ? `${title}.xlsx` : 'Exported Data.xlsx'
        var ws = XLSX.utils.json_to_sheet(data, { raw: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Data')
        XLSX.writeFile(wb, exportFileName)
    },
    exportCsv(data, title) {
        let wb = XLSX.utils.book_new()
        let exportFileName = title ? `${title}.csv` : 'Exported Data.csv'
        var ws = XLSX.utils.json_to_sheet(data, { raw: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Data')
        XLSX.writeFile(wb, exportFileName)
    },
    goToSection(idName) {
        // this.$refs.[''].$el.scrollIntoView({ behavior: 'smooth' });
      document.getElementById(idName).scrollIntoView({
        behavior: "smooth"
      });
    // 	var element = this.$refs[refName];
    //   console.log(element);
    //   var top = element.offsetTop;
      
    //   window.scrollTo(0, top);
    }
}

export default methods;