<template>
    <div id="winfun-collection">

        <div class="winfun-big-title">
            <h2>WINFUN COLLECTION</h2>
        </div>
        <div class="ratio-container">
            <div class="ration-child">
                <iframe
                    class="ratio-child"
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/ukgvTE3A0Ic"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                ></iframe>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    .winfun-big-title {
        background-color: #ee125b;
        color: white;
        padding: 20px 0;
    }

    .winfun-big-title h2 {
        margin: 0;
        text-align: center;
        font-size: 5vw;
        font-weight: bold;
    }

    #app.mobile .winfun-big-title h2 {
        font-size: 7.5vw;
    }




    .ratio-container {
        width: 100%;
        padding-top: 56.25%; /* 16:9 Aspect Ratio */
        position: relative; /* If you want text inside of it */
    }

    /* If you want text inside of the container */
    .ratio-child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
</style>