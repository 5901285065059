<template>
    <div id="marketplace">
        
        <div class="container">
            <h4>Find Winfun collection at our marketplace</h4>
            <div class="row">
                <div
                    v-for="(marketplace, index) in marketplaces"
                    :key="index"
                    class="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 marketplace-box-container"
                >
                    <div
                        class="marketplace-box"
                        :title="marketplace.image"
                        @click="openInNewTab(marketplace.url)"
                    >
                        <img
                            :src="require('@/assets/images/' + marketplace.image + '.png')"
                            :alt="marketplace.image + ' Logo'"
                            style="width: auto; height: auto; max-height: 100%; max-width: 100%;"
                        >
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {
                marketplaces : [
                    {image: "shopee", url: "https://shopee.co.id/winfunindonesia"},
                    {image: "tokopedia", url: "https://www.tokopedia.com/winfunindonesia"},
                    {image: "blibli", url: "https://www.blibli.com/merchant/winfun-official-store/WIO-60034"},
                    {image: "zalora", url: "https://www.zalora.co.id/store/winfun-indonesia"},
                    {image: "orami", url: "https://www.orami.co.id/shopping/brand/win-fun"},
                    {image: "bukalapak", url: "https://www.bukalapak.com/winfun-official-official"},
                    {image: "lazada", url: "https://www.lazada.co.id/shop/winfun-official"},
                ]
            }
        },
        mounted () {

        },
        methods : {
            openInNewTab(url) {
               window.open(url, '_blank').focus();
            }
        }
    }
</script>

<style scoped>
    #marketplace {
        padding: 100px 0;
        /* background-color: #FFF3F6; */
    }

    h4 {
        text-align: center;
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 50px;
    }

    .marketplace-box-container {
        padding: 10px 30px;
    }

    .marketplace-box {
        height: 144px;
        border-radius: 0.375rem;
        border: 2px solid black;
        box-shadow: 4px 4px 0px #1D1D1D;

        display: flex;
        align-items: center;
        justify-content: center;

        padding: 30px;
        background-color: white;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
    }

    .marketplace-box:hover {
        transform: scale(1.1);
    }
</style>