<template>
    <div id="statistics">
        <div class="container">
            <h4>Our Success, Defined in Key Statistics</h4>
            <div class="row">
                <div
                    v-for="(dat, index) in data"
                    :key="index"
                    class="col-12 col-lg-3 col-md-6 col-sm-12 col-xs-12"
                    style="padding: 10px 15px"
                >
                    <div class="statistics-box h-100">
                        <div class="text-center">
                            <i :class="dat.icon" class="fa-3x"></i>
                            <h5 class="mt-2 mb-2">{{ dat.title }}</h5>
                            <p class="mb-0">{{ dat.value }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                data : [
                    {icon: "fal fa-users-crown", title: "Satisfied Families", value: "890,000+"},
                    {icon: "fal fa-box-heart", title: "Products Sold", value: "1.2 Million+"},
                    {icon: "fal fa-user-hard-hat", title: "Safety Tests Passed", value: "100%"},
                    {icon: "fal fa-store", title: "Retail Partners", value: "2,000+"},
                ]
            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    #statistics {
        padding: 100px 0;
    }

    .statistics-box {
        box-shadow: 0px 2px 4px rgba(148, 163, 184, 0.05),0px 6px 24px rgba(235, 238, 251, 0.4);
        border-radius: 15px;
        
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 40px;
    }

    #statistics h4 {
        text-align: center;
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 30px;
    }

    #statistics i {
        color: #ee125b;
    }

    #statistics h5 {
        color: #94A3B8;
    }

    #statistics p {
        color: #1E293B;
        font-size: 36px;
        font-weight: bold;
    }
</style>