<template>
  <div id="app" :class="{
    'mobile' : mobile
  }">
    <router-view/>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        mobile: this.isMobile()
      }
    },
    mounted () {

    },
    methods : {

    }
  }
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*/

  .align-middle {
    display: flex;
    align-items: center;
  }
</style>
