<template>
    <div id="about">

        <div class="container about-content">
            <div class="row m-0">
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div class="winfun-id-title">
                        <div class="winfun-id-title-box">
                            <span>Winfun&reg;</span>
                        </div>
                    </div>
            
                    <p>
                        &nbsp;&nbsp;&nbsp;Winfun&reg; is a Hong Kong-based company that was established in 2005 to provide innovative and engaging baby and kids products that aid in their cognitive, physical and social development. Our range of products are designed to bring a sense of enjoyment and excitement to the learning process, encouraging children to explore new concepts and ideas while having fun. Our goal is to help children "win" new skills by sparking their imagination and curiosity in a playful and interactive way. As the famous American football coach Bum Phillips once said, "Winning is only half of it, having fun is the other half", and we believe that this sentiment perfectly encapsulates our approach to child development.
                    </p>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 align-middle" :style="mobile ? 'padding: 0px' : ''">
                    <div class="ratio-container">
                        <div class="ratio-child">
                            <iframe
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/yOXnAH8v6Mg"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        data () {
            return {
                mobile : this.isMobile(),
            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    #about {
        min-height: 75vh;
        background-color: #f5f5f5;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #about .about-content {
        padding: 100px 0;
    }

    #about .about-content .winfun-id-title {
        font-size: 3em;
        font-weight: 700;
        color: white;
    }

    #about .about-content .winfun-id-title-box {
        border: 3px solid #ee125b;
        background-color: #ee125b;
        border-left: 0;
        padding: 10px 50px 10px 50px;
        display: inline-block;
        border-radius: 0 30px 30px 0;
        /* offset-x | offset-y | blur-radius | spread-radius | color */
		box-shadow: -250px 0px 0px #ee125b;
    }

    #about .about-content p {
        padding: 50px;
        margin: 0;
        font-size: 1em;
        font-weight: 500;
        color: #131843;
        text-align: justify;
    }


    .ratio-container {
        width: 100%;
        padding-top: 300px;
        position: relative; /* If you want text inside of it */

        border-radius: 15px;
    }

    #app:not(.mobile) .ratio-container {
        padding-top: 56.25%; /* 16:9 Aspect Ratio */
        background-color: #ee125b;
        box-shadow: 20px 25px 0px #ee125b;
    }

    /* If you want text inside of the container */
    .ratio-child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    #app:not(.mobile) .ratio-child {
        border-radius: 15px;
        overflow: hidden;
    }
</style>