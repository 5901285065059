<template>
    <div id="cps">

        <div class="container cps-content">
            <div class="winfun-id-title">
                <div class="winfun-id-title-box">
                    <span>CPS Parenting System</span>
                </div>
            </div>

            <div class="row m-0">
                <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 align-middle pt-5">
                    <img src="@/assets/images/cps.png" style="width: 100%; height: auto;">
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 pt-5">
                    <p class="mini-title">Cognitive Development</p>
                    <p>Cognitive development, is the construction of though processes, including problem solving, creativity and curiosity. from childhood through adolescence to adulthood.</p>
                    <p class="mini-title">Physical Development</p>
                    <p>Physical development, is the process that starts in human infancy and continues into late adolescence concentrating on gross and fine motor skills as well as Sensory.</p>
                    <p class="mini-title">Social Development</p>
                    <p>Social development, it refers to the process by which a child learns to interact with others around them. It includes the child's Communication, Emotional and Self-expression.</p>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 align-middle pt-5" :style="mobile ? 'padding: 0px' : ''">
                    <div class="ratio-container">
                        <div class="ration-child">
                            <iframe
                                class="ratio-child"
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/eDNP3LlPk7Q?si=o-LnJvoUUzHIfDtD"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        data () {
            return {
                mobile : this.isMobile(),
            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    #cps {
        min-height: 75vh;
        background-color: #f5f5f5;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    #cps .cps-content {
        padding: 100px 0;
    }

    #cps .cps-content .winfun-id-title {
        font-size: 3em;
        font-weight: 700;
        color: white;
    }

    #cps .cps-content .winfun-id-title-box {
        border: 3px solid #ee125b;
        background-color: #ee125b;
        border-left: 0;
        padding: 10px 50px 10px 50px;
        display: inline-block;
        border-radius: 0 30px 30px 0;
        /* offset-x | offset-y | blur-radius | spread-radius | color */
		box-shadow: -250px 0px 0px #ee125b;
    }

    #cps .cps-content p {
        padding-top: 10px;
        margin: 0;
        font-size: .8em;
        font-weight: 500;
        color: #131843;
        text-align: justify;
    }

    #cps .cps-content p.mini-title {
        padding-top: 25px;
        font-size: 1.2em;
        font-weight: 700;
        color: #ee125b;
        text-align: left;
    }


    .ratio-container {
        width: 100%;
        padding-top: 300px;
        position: relative; /* If you want text inside of it */

        border-radius: 15px;
    }

    #app:not(.mobile) .ratio-container {
        padding-top: 56.25%; /* 16:9 Aspect Ratio */
        background-color: #ee125b;
        box-shadow: 20px 25px 0px #ee125b;
    }

    /* If you want text inside of the container */
    .ratio-child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    #app:not(.mobile) .ratio-child {
        border-radius: 15px;
    }
</style>