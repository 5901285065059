//Import Css
import 'bootstrap/dist/css/bootstrap.css';

//Import Fontawesome
import '@/assets/fontawesome/all.min.css';

//Import SweetAlert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// import mixins
import mixinMethods from '@/assets/mixin/methods.js'
Vue.mixin({
  // Mixin Methods
  methods : mixinMethods
})

//Import vue_universal_table
import vueUniversalTable from '@/components/table/vue_universal_table.vue'
Vue.component('vue-universal-table' , vueUniversalTable);

//Google Analytics
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
}, router);

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
