<template>
    <div id="home">

        <w-navbar></w-navbar>

        <!-- CONTENT START -->
        <hero-slideshow></hero-slideshow>
        <about></about>
        <statistics></statistics>
        <cps></cps>
        <vision></vision>
        <!-- <winfun-collection></winfun-collection> -->
        <marketplace></marketplace>
        <!-- CONTENT END -->

        <w-footer></w-footer>

    </div>
</template>

<script>
    import wNavbar from '@/components/navbar.vue'
    import wFooter from '@/components/footer.vue'

    import heroSlideshow from './children/heroSlideshow.vue'
    import statistics from './children/statistics.vue'
    import about from './children/about.vue'
    import cps from './children/cps.vue'
    import vision from './children/vision.vue'
    import winfunCollection from './children/winfunCollection.vue'
    import marketplace from './children/marketplace.vue'
    
    export default {
        name: 'Home',
        meta: { title: 'Winfun Indonesia' },
        data() {
            return {

            }
        },
        components : {
            wNavbar,
            wFooter,
            heroSlideshow,
            statistics,
            about,
            cps,
            vision,
            winfunCollection,
            marketplace,
        },
        mounted() {

        },
        methods: {

        }
    }
</script>


<style scoped>
    
</style>