import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeMain from '../views/Home/homeMain.vue'
import Home from '../views/Home/home.vue'
import NotFound from '../views/404.vue'

//Projects
// const ProjectsMain = () => import('@/views/Projects/Main')
// import ProjectsSudokuSolver from '@/views/Projects/ProjectsSudokuSolver'
// import ProjectsOpenAIChatPrompt from '@/views/Projects/ProjectsOpenAIChatPrompt'
// import ProjectsChartGenerator from '@/views/Projects/ProjectsChartGenerator'
// import ProjectsIdSalt from '@/views/Projects/ProjectsIdSalt'
// import ProjectsThumbnailGenerator from '@/views/Projects/ProjectsThumbnailGenerator'

Vue.use(VueRouter)

const routes = [
  //Projects
  // {
  //   path : '/projects',
  //   component : ProjectsMain,
  //   children : [{
  //     path : '',
  //     name : 'Projects',
  //     component : NotFound
  //   },{
  //     path : 'sudoku-solver',
  //     name : 'ProjectsSudokuSolver',
  //     component : ProjectsSudokuSolver
  //   },{
  //     path : 'chat',
  //     name : 'ProjectsOpenAIChatPrompt',
  //     component : ProjectsOpenAIChatPrompt
  //   },{
  //     path : 'chart-generator',
  //     name : 'ProjectsChartGenerator',
  //     component : ProjectsChartGenerator
  //   },{
  //     path : 'id-salt',
  //     name : 'ProjectsIdSalt',
  //     component : ProjectsIdSalt
  //   },{
  //     path : 'thumbnail-generator',
  //     name : 'ProjectsThumbnailGenerator',
  //     component : ProjectsThumbnailGenerator
  //   }]
  // },

  {
    path: '/',
    name: 'HomeMain',
    component: HomeMain,
    children : [{
      path : '',
      name : 'Home',
      component : Home
    }]
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
