<template>
    <div id="hero-slideshow">
        <big-slideshow
            :slideshowList="images"
        ></big-slideshow>
    </div>
</template>

<script>
    import bigSlideshow from '@/components/bigSlideshow.vue'

    export default {
        data () {
            return {
                images : [
                    // "https://picsum.photos/1920/1080?random=1",
                    // "https://picsum.photos/1920/1080?random=2",
                    // "https://picsum.photos/1920/1080?random=3",
                    // "https://picsum.photos/1920/1080?random=4",
                    // "https://picsum.photos/1920/1080?random=5"
                    "https://www.youtube.com/embed/3QBMDIZOKHY",
                    "https://www.youtube.com/embed/DgKkZYyw-p0",
                    "https://www.youtube.com/embed/Sx8qF4zCHkc",
                    "https://www.youtube.com/embed/NtsQ7tr-U1U",
                    "https://www.youtube.com/embed/V0sZlcjy4-8",
                    "https://www.youtube.com/embed/jCEYGY111Yo",
                ]
            }
        },
        components : {
            bigSlideshow,  
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>

</style>