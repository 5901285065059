<template>
    <div id="big-slideshow">
        <div class="slideshow" v-if="slideshowImages != null">
            <div class="ratio-container">
            </div>

            <div class="slideshow-background">
                <transition-group :name="'fade-' + transitionMode">
                    <div 
                        v-for="(image, index) in slideshowImages" 
                        :key="image" 
                        class="ratio-container" 
                        v-if="shownSlideshow === index"
                    >
                        <div class="ratio-child">
                            <iframe
                                v-if="image.includes('https://www.youtube.com')"
                                width="100%"
                                height="100%"
                                :src="image"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                            ></iframe>
                            <img
                                v-else
                                class="slideshow-background-image"
                                :src="require(`@/assets/images/${image}`)"
                            >
                        </div>
                    </div>
                </transition-group>
            </div>


            <div class="slideshow-navigation">
                <div class="slideshow-nav" @click="slideshowGo('left')">
                    <i
                        class="fas fa-chevron-left"
                        :class="{'disabled' : transitionOnGoing}"
                    ></i>
                </div>
                <div class="slideshow-nav" @click="slideshowGo('right')">
                    <i
                        class="fas fa-chevron-right"
                        :class="{'disabled' : transitionOnGoing}"
                    ></i>
                </div>
            </div>
        </div>
        <div class="slideshow" v-else>
            <div style="height: 100%; width: 100%; background: #395F65; display: flex; align-items: center; justify-content: center;">
                <p style="color: white; margin: 0; font-size: 5em;">No Image Found</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                shownSlideshow : 0,
                transitionMode : 'left',
                transitionOnGoing : false,
                transitionOnGoingWrapper : false,
                slideshowImages : null,
            }
        },
        props : ['slideshowList'],
        mounted () {
            
        },
        watch : {
            'slideshowList' : {
                handler () {
                    this.slideshowImages = this.slideshowList;
                    this.shownSlideshow = 0;
                },
                immediate : true
            }
        },
        methods : {
            jumpSlideshowNo (position) {
                let mode = 'left';
                if (position > this.shownSlideshow) mode = 'right';
                else mode = 'left';

                this.slideshowGo(mode, position);
            },
            slideshowGo (mode, position) {
                if (this.slideshowImages == null) return;
                if (this.transitionOnGoingWrapper) return;
                this.transitionMode = mode;

                this.transitionOnGoingWrapper = true;
                
                setTimeout(()=>{
                    if (this.slideshowImages == null) return;
                    this.transitionOnGoing = true;

                    setTimeout(() => {
                    if (this.slideshowImages == null) return;

                        if (position != null) {
                            this.shownSlideshow = position;
                        } else if (mode=='left') {
                            if (this.shownSlideshow-1 < 0) this.shownSlideshow = this.slideshowImages.length-1;
                            else this.shownSlideshow--;
                        } else {
                            if (this.shownSlideshow+1 >= this.slideshowImages.length) this.shownSlideshow = 0;
                            else this.shownSlideshow++;
                        }
                        
                        this.transitionOnGoing = false;
                        this.transitionOnGoingWrapper = false;
                    }, 200);  
                }, 10);
            },
        }
    }
</script>

<style scoped>
    #big-slideshow {
        overflow: hidden;
        background-color: #f5f5f5;
    }

    #big-slideshow .slideshow {
        width: 100vw;
        position: relative;
    }

    #big-slideshow .slideshow-content {
        position: absolute;
        width: 100%;
    }

    #big-slideshow .slideshow-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    #big-slideshow .slideshow-background .slideshow-background-image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    #big-slideshow .slideshow-navigation {
        
    }

    #big-slideshow .slideshow-navigation .slideshow-nav {
        position: absolute;
        bottom: 25%;

        
        height: 50%;
        width: 20vw;
        max-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    #big-slideshow .slideshow-navigation .slideshow-nav:last-child {
        right: 0;
    }

    #big-slideshow .slideshow-navigation .slideshow-nav i {
        color: white;
        font-size: 5vw;
        text-shadow: 0px 0px 10px black;
    }

    /* RATIO CSS START */
    .ratio-container {
        width: 100%;
        padding-top: 300px;
        position: relative; /* If you want text inside of it */
    }

    #app:not(.mobile) .ratio-container {
        padding-top: 56.25%; /* 16:9 Aspect Ratio */
        background-color: #f5f5f5;
    }

    /* If you want text inside of the container */
    .ratio-child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    /* RATIO CSS END */



    
    /* Enter */
    .fade-left-enter-from,
    .fade-right-enter-from {
    transform: translateX(200px);
    opacity: 0;
    }
    .fade-left-enter-active,
    .fade-right-enter-active {
    transition: all .2s ease;
    }
    .fade-left-enter-to,
    .fade-right-enter-to {
    transform: translateX(0);
    opacity: 1;
    }

    /* Leave */
    .fade-left-leave-from,
    .fade-right-leave-from {
    transform: translateX(0);
    opacity: 1;
    }
    .fade-left-leave-active,
    .fade-right-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .fade-left-leave-to,
    .fade-right-leave-to {
    transform: translateX(-200px);
    opacity: 0;
    }

</style>