<template>
    <div id="footer">
        
        <div class="container">
            <div class="row" style="margin: 100px 0;">
                <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 mobile-center">
                    <img
                        src="@/assets/images/winfun_logo.png"
                        style="max-height: 50px; width: auto; max-width: 100%;"
                    >
                </div>
                <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-12 mobile-center">
                    <p class="quote">{{ quotes[randomInt(0, quotes.length+1)] }}</p>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 offset-lg-2 offset-md-1 mobile-center">
                    <h5>Contacts</h5>
                    <ul class="contacts">
                        <li><a href="mailto:winfun.indonesia@gmail.com">winfun.indonesia@gmail.com</a></li>
                        <li><a href="tel:+6281908672707">081908672707</a></li>
                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-7 mobile-center">
                    <p class="copyright">&copy; Winfun Indonesia 2023. All rights reserved.</p>
                </div>
                <div class="col-12 col-sm-5 social-media">
                    <div
                        v-for="(single, index) in socialMedia"
                        class="social-media-box"
                        :key="index"
                        :title="single.title"
                        @click="openInNewTab(single.url)"
                    >
                        <i :class="single.icon"></i>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data () {
            return {
                quotes : [
                    "Winfun: Where innovation meets joyful learning, fueling imagination and growth in every child.",
                    "Crafting smiles and skills—Winfun is your partner in shaping a brighter, happier childhood.",
                    "Guided by experts, trusted by parents; Winfun is the gold standard in enriching play.",
                    "From the first toy to the first day of school, Winfun is with you every step.",
                    "With Winfun, every playtime is a leap toward a smarter, happier future for your child.",
                    "Safety and fun coalesce at Winfun, offering peace of mind in your child's playtime.",
                    "Winfun toys are more than playthings; they're your child's first teachers and lifelong friends.",
                    "Revolutionizing playtime, Winfun combines fun and education to unleash your child's full potential.",
                    "Skill-building and imagination—Winfun makes every moment a valuable experience for young minds.",
                    "Endorsed by experts and loved by families, Winfun is the name you can trust."
                ],
                socialMedia : [
                    {icon: "fab fa-instagram", title: "Instagram", url: "https://www.instagram.com/winfun_indonesia"},
                    {icon: "fab fa-facebook-f", title: "Facebook", url: "https://www.facebook.com/WinfunIndonesia"},
                    {icon: "fab fa-tiktok", title: "Tiktok", url: "https://www.tiktok.com/@winfun_indonesia"},
                    {icon: "fab fa-youtube", title: "Youtube", url: "https://www.youtube.com/@winfunindonesia989"},
                ]
            }
        },
        mounted () {

        },
        methods : {
            openInNewTab(url) {
               window.open(url, '_blank').focus();
            }
        }
    }
</script>

<style scoped>
    #footer .quote {
        color: #94A3B8;
        font-size: 18px;
    }
    #footer ul.contacts {
        list-style: none;
        padding: 0;
    }

    #footer ul.contacts li a {
        text-decoration: none;
        color: #94A3B8;
    }
    #footer ul.contacts li a:hover {
        text-decoration: underline;
    }

    #footer .copyright {
        color: #94A3B8;
        margin: 0;
        font-size: 12px;
    }

    #footer .social-media {
        display: flex;
        flex-direction: row-reverse;
    }

    #app.mobile #footer .social-media {
        justify-content: center;
        margin: 10px 0
    }

    #footer .social-media .social-media-box {
        padding: 0 10px;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
    }

    #footer .social-media .social-media-box:hover {
        transform: scale(1.1);
        transform-origin: bottom center;
    }

    #footer .social-media .social-media-box i {
        font-size: 1.2em;
        color: #ee125b;
    }

    #app.mobile .mobile-center {
        text-align: center;
    }
</style>