<template>
    <router-view></router-view>
</template>

<script>
    export default {
        data () {
            return {

            }
        },
        mounted () {

        },
        methods : {

        }
    }
</script>

<style scoped>
    
</style>